@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  h3 {
    margin: 0;
  }

  a {
    display: block;
    width: 25px;
    height: 25px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 48px;
  align-items: flex-start;
}

.value {
  color: $gray-dark;

  @media screen and (min-width: $screen-xl) {
    width: 55%;
    padding-left: 0.25rem;
  }
}

.label {
  white-space: nowrap;
  font-weight: $font-weight-medium;

  @media screen and (max-width: $screen-xl) {
    white-space: normal;
  }

  @media screen and (min-width: $screen-xl) {
    width: 45%;
  }
}

.valueContainer {
  display: grid;
  font-size: $font-size-xxs;
  color: var(--primary-darker-color);
  grid-template-columns: 1.2fr 2fr;
  margin-bottom: 0.5rem;
  word-break: break-word;
  line-height: 1rem;

  @media screen and (min-width: $screen-xl) {
    display: flex;
  }
}

.column {
  width: 50%;

  &:first-child {
    padding-right: 0.5rem;
  }

  &:last-child {
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: $screen-xl) {
  .info {
    flex-direction: column;
    align-items: flex-start;
    border-collapse: separate;
    width: 100%;
    text-align: left;
    border-radius: 4px !important;
    border: 1px solid $gray-lines;
    padding-bottom: 0;
    margin-bottom: 48px;

    > :first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    > :last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .info span {
    border-right: none;
    padding: 12px 16px;
  }

  .info div:nth-child(odd) {
    background-color: var(--primary-lightest-color);
  }

  .valueContainer {
    grid-template-columns: 1fr 2fr;
    width: 100%;
    margin-bottom: 0;
    color: var(--primary-color);
  }
}

:export {
  screenXl: $screen-xl;
}