@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.container {
  display: flex;
  align-items: center;
  font-size: $font-size-xxs;
}

.name {
  font-size: $font-size-xs;
  font-weight: $font-weight-semibold;
  color: var(--primary-dark-color);
  margin-bottom: 8px;
}

.description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: $font-size-xxs;
  color: var(--primary-light-color);
  margin-bottom: 8px;

  &:empty {
    display: none;
  }
}

.quantity {
  height: 16px;
  font-size: $font-size-xxs;
  color: var(--primary-dark-color);
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(100% - 100px);
  padding: 0 20px 0 30px;
  box-sizing: border-box;
}

.imageContainer {
  width: 80px;
  height: 80px;
  background-color: $white;

  @media screen and (max-width: $screen-xl) {
    padding-left: 15px;
  }
}

.image {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 0;
  border-radius: 8px;
  border: 1px solid $gray-lines;
  object-fit: contain;
}

.value {
  color: $gray-dark;
}

@media screen and (max-width: $screen-xl) {
  .container {
    border: 1px solid $gray-lines;
    padding: 15px 0;
    margin-bottom: 0;
  }

  .image {
    border: none;
  }
}