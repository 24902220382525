@import "/src/styles/breakpoints";

.container {
    width: 1320px;
    max-width: 100%;
    padding: 0 1rem;
    margin: auto;
    box-sizing: border-box;

    @media screen and (max-width: $screen-l) {
        max-width: 100%;
    }
}