@import "src/styles/fonts";
@import "src/styles/colors";
@import "src/styles/breakpoints";

.timelineContainer {
  border-radius: 24px;
  background: $light-gray;
  height: 152px;
  padding: 32px;
  margin-top: 24px;
}

.timeline {
  height: 152px;
  overflow-x: hidden;
}

.divider {
  border-left: 1px solid $gray;
  height: 24px;
  margin: 16px 0px;
  margin-left: 2px;
}

.record {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statusInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--primary-darker-color);
  font-size: $font-size-xs;
  padding-top: 8px;
  padding-right: 10px;
  width: calc(100% - 46px);
}

.bullet {
  font-size: $font-size-l;
  color: var(--primary-lighter-color) !important;
  width: 36px;
}

.date {
  color: $gray-dark !important;
  font-size: $font-size-xxs;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.isModal {
  height: 370px;
  margin-top: 0px;
  font-family: $font-family;

  .timeline {
    height: 368px;
  }
}

.noStatus {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: $gray-dark;
  text-align: center;
  line-height: 1.5;
}

@media screen and (max-width: $screen-xl) {
  .timelineContainer {
    margin-top: 0;
    padding-top: 12px;
    padding-bottom: 24px;
  }

  .divider {
    border-left: 1px solid $gray;
    height: 24px;
    margin: 0;
    margin-left: 2px;
  }
}