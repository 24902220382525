@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.mainContainer {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $screen-xl) {
    margin-top: 1rem;
  }
}

.colRight {
  background-color: $light-gray;
}

.details {
  padding: 0 1.5rem;
}

.packages {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  color: var(--primary-color);
  box-sizing: border-box;

  h1 {
    margin-top: 0;
  }
}

.yourPackages {
  display: flex;
  flex-direction: column;
  color: var(--primary-darker-color);
  font-size: $font-size-m;
}

.yourPackagesTitle {
  margin: 0;
}

.dropdown {
  border-radius: 16px !important;
}

.dropdownTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdownTitle {
  display: flex;
  padding-left: 6px;
  margin: 0.75rem 0;
}

.dropdownSubTitle {
  font-size: 0.75rem !important;
  margin-bottom: 12px;
  padding-left: 6px;
  color: $gray-dark;

  span {
    font-weight: $font-weight-semibold;
  }
}

.item {
  display: flex;
  flex-direction: column;
  margin: 0 12px 40px 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $screen-xl) {
    margin-bottom: 0;
  }
}

.items {
  display: flex;
  flex-direction: column;
  height: 210px;
  overflow-y: scroll;
  margin-right: 20px;
}

.itemsContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  background: $light-gray;
  border-radius: 24px;
  margin: 24px 24px 0 24px;
}

.noItem {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: $gray-dark;
}

.yourAccount {
  color: $gray-dark;
  margin-bottom: 15px;
}

.imageBanner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-self: center;
  width: 100%;
  padding-bottom: 4px;

  img {
    width: 100%;
  }
}

.homeBanner {
  width: 100%;
  height: 100%;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

/* width */
.items::-webkit-scrollbar {
  padding: 16px;
  width: 2px;
}

/* Track */
.items::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 24px;
}

/* Handle */
.items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 24px;
  background-clip: padding-box;
}

/* Handle on hover */
.items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: $screen-xl) {
  .container {
    flex-direction: column;
    padding: 24px 16px;
  }

  .imageBanner {
    width: auto;
    height: auto;
  }

  .packages {
    padding: 0 0 32px 0;
    width: auto;
  }

  .details {
    width: auto;
    background-color: $white;
    padding: 32px 0 0 0;
  }

  .select {
    color: black;
    font-size: $font-size-xxs;
  }

  .selectNumber {
    color: $gray-dark;
    font-family: $font-family;
    padding-right: 5px;
  }

  .selectCarrier {
    color: var(--primary-color);
    padding-right: 4px;
  }

  .selectTracking {
    color: var(--primary-darker-color);
  }

  .selectOption {
    padding: 8px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .value {
      color: $gray-dark;
    }
  }

  .itemsContainer {
    padding: 0;
    height: 260px;
    background: $white;
    border-radius: 24px;
    margin: 0;
  }

  .items {
    margin: 0 2px 0 0;
    height: 224px;
    overflow: auto;

    > :first-child {
      > :first-child {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }

    > :last-child {
      > :first-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
}

:export {
  fontSizeXxs: $font-size-xxs;
  grayLines: $gray-lines;
  screenXl: $screen-xl;
  white: $white;
}

.yourPackagesTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
  height: 38px;
}