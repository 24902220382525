@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.package {
  border: 1px solid $gray-lines;
  border-radius: 16px;
  align-items: center;
  padding: 16px;
  color: var(--headings-color);
  background-color: $white;
  font-size: $font-size-xs;
  margin-bottom: 24px;
  font-family: $font-family;
}

.packagesWrapper {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.status {
  white-space: nowrap;
  text-align: center;
  width: 40%;
}

.statusIcon {
  width: 48px;
}

.statusDetails {
  width: 60%;
  text-align: left;
  font-size: $font-size-xxs;
  justify-content: space-between;
}

.clicked {
  background-color: var(--primary-darkest-color);
  color: $white;

  &:hover {
    background-color: var(--primary-dark-color);
  }
}

.valueContainer {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;

  .valueLabel {
    font-weight: 500;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.value {
  display: inline-block;
  color: var(--primary-lighter-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .date {
    text-transform: capitalize;
  }
}

@media screen and (max-width: $screen-xl) {
  .package {
    width: auto;
    margin-bottom: 0;
    border-bottom: none;
    color: $white;
    background-color: var(--primary-color);
    justify-content: start;
    border-radius: 16px 16px 0px 0px;
  }

  .clicked {
    color: var(--primary-color);
    background-color: $white;
    padding: 15px 10px;

    &:hover {
      background-color: $white !important;
      filter: none;
      cursor: default;
    }
  }

  .statusDetails {
    width: 100%;
    height: auto;
    padding-left: 15px;
    box-sizing: border-box;
  }

  .statusValue {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .status {
    width: 115px;
    margin-right: 10px;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    text-align: left;
  }

  .latestEvent {
    width: calc(100% - 135px);

    li {

      max-width: 100%;
      max-height: 20px;
      padding: 5px 10px;
      border-radius: 4px;
      background-color: var(--primary-lightest-color);
      list-style-position: inside;
      list-style-type: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: $screen-xs) {
        width: fit-content;
      }
    }
  }
}

@media screen and (min-width: $screen-xxl) {
  .package {
    padding: 16px 24px;
  }
}

:export {
  screenXl: $screen-xl;
}