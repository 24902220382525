@import "src/styles/fonts";
@import "src/styles/colors";
@import "src/styles/breakpoints";

.shipmentStatus {
  background: white;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid $gray-lines;
  padding: 24px 24px 0px 24px;
  margin: 24px 0px 0px 0px;
}

.title {
  font-size: $font-size-m;
}

.date {
  color: $gray-dark !important;
  font-size: $font-size-xxs;
}

.historyButton {
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-xxs;
}

.noStatus {
  display: flex;
  justify-content: center;
  color: var(--text-color);
}

.furtherDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 32px;
  margin-bottom: 24px;
  background-color: var(--primary-darkest-color);
  color: $white;
  border-radius: 0px 0px 16px 16px;
  font-size: $font-size-xxs;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fafbfc;
  border: 1px solid $gray-lines;
  box-shadow: 24;
  border-radius: 16px;
  height: 526px;
  font-family: $font-family;
}

.modalHeader {
  margin: 32px 25px 0px 32px !important;
  font-family: $font-family;
  color: var(--primary-darker-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  padding: 16px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 24px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: $screen-xl) {
  .shipmentStatus {
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    background-color: $light-gray;
  }

  .historyButton {
    height: 59px;
    align-items: flex-start;
  }

  .title {
    margin: 0;
    padding: 24px 0 0 24px;
  }

  .historyButton {
    margin-top: 0;
  }

  .furtherDetails {
    padding: 16px;
  }

  .modalBox {
    width: 90%;
  }
}

:export {
  screenXl: $screen-xl;
}

.shipmentStatusTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: $screen-xl) {
    height: 35px;
  }

  .title {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
  }
}