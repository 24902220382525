@import "src/styles/breakpoints";
@import "src/styles/fonts";
@import "src/styles/colors";

.notFoundPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  background-image: url("../../assets/images/not-found-page.jpeg");
  background-size: 100% 100%;
}

.message {
  text-align: center;
  padding: 16px;
  width: 70%;
}

.backButton {
  padding: 8px;
}

.box {
  border: 2px solid $gray-lines;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 340px;
  background: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: $screen-xl) {
  .notFoundPage {
    height: 100%;
  }

  .box {
    margin: 16px;
  }

  .container {
    min-height: 500px;
  }
}
