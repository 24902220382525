@import "src/styles/colors";
@import "src/styles/fonts";

.container {
  display: flex;
  border: 1px solid $gray-lines;
  border-radius: 16px;
  background: $white;
  font-size: $font-size-s;
  flex-direction: column;
  align-items: center;
  color: var(--text-color);
}

.titleContainer {
  font-family: $font-family;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border: none;
  width: 100%;
  box-shadow: none;
  border-radius: 16px;
}

.data_values {
  width: 100%;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 222ms;

  >div {
    opacity: 0;
    transition: opacity 800ms ease;
  }

  &.show {
    min-height: 0px;
    height: 320px;
    overflow: visible;
    -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 222ms;

    >div {
      opacity: 1;
      transition: opacity 800ms ease;
    }
  }
}

.dropdownIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: $white;
  border: 1px solid $gray-lines;
  border-radius: 5px;
  transition: transform .3s ease;

  &.rotate {
    transition: transform .3s ease;

    svg {
      transform: rotate(180deg);
      transition: transform .3s ease;
    }
  }
}