@import "/src/styles/colors";
@import "/src/styles/fonts";
@import "/src/styles/breakpoints";

.column {
    padding: 0 0.75rem;
    box-sizing: border-box;
    max-width: 50%;

    @media (max-width: $screen-l) {
        flex: 0 0 100%;
    }
}