@import "src/styles/colors";

.closeIcon {
  cursor: pointer;
}

.lines {
  stroke: var(--primary-dark-color);
}

.white {
  stroke: $white;
}
