@import "src/styles/fonts";
@import "src/styles/colors";

.main {
  position: relative;
  width: 3rem;
  height: 3rem;
}

.text {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xxxs;
  color: var(--primary-color);
  word-break: break-word;
  flex-direction: column;
}

.unknownText {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary-color);
  font-size: $font-size-s;
}

.arrivedText {
  color: #38cb89;
}

.errorText {
  color: #ff0000;
}

.line {
  fill: var(--primary-light-color);
}

.iconWrapper {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 25px;
    fill: var(--primary-light-color)
  }

  &.preTransit {
    svg {
      width: 18px;
    }
  }

}