$white: #ffffff;
$gray-lines: #e9ecef;
$gray: #d9d8e9;
$light-gray: #fafbfc;
$gray-dark: #7f7d92;
$white-light: #ffffff1a;
$red: #ff0000;

/* General */
$white: #ffffff;
$black: #000000;
$orange: #ff9800;

/* Grayscale */
$black-90: #141216;
$black-50: #5B5F6C;
$black-40: #9CA3AF;
$black-30: #D1D5DB;
$black-20: #E5E7EB;
$black-10: #F3F4F6;

/* Stoplight */
$success: #4caf50;
$warning: #EEFF41;
$error: #FF4141;

/* Shipping */
$gray: #d9d8e9;
$gray-dark: #7f7d92;
$white-light: #ffffff1a;