@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.topBanner {
  background-color: var(--primary-darkest-color) !important;
  color: $white !important;
  border-radius: 0 !important;
  justify-content: center;
  text-align: center;
}

.topBannerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1000px;
}

.topBannerText {
  width: 90%;
}

.closeButton {
  padding-left: 90%;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: $screen-xl) {
  .topBannerContent {
    min-width: 0;
  }
}