@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba($black, 1), rgba($black, 0));
  }
}

.loginContent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;

  img {
    min-height: 40px;
    max-height: 50px;
    max-width: 350px;
  }
}

.contentWithColor {
  background-color: var(--background-color);
}

.login {
  position: relative;
  width: 880px;
  max-width: 100%;

  h1 {
    text-align: center;

    &.homeTitleColor {
      color: var(--home-title-color-shipping) !important;
    }
  }

  .subtitle {
    width: 50%;
    margin: 0 auto .75rem auto;
    color: $white;
    line-height: 1.2;
    text-align: center;

    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }
  }

  @media screen and (max-width: $screen-xl) {
    width: 90%;
    text-align: center;
  }
}

.trackYourOrderForm {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding: 2rem;
  background-color: rgba($color: $white, $alpha: .95);
  border-radius: 4px;
  text-align: left;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }

  .trackYourOrderFormContainer {
    display: flex;
    width: calc(100% - 114px);

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      width: 100%;
      text-align: left;
    }

    >div {
      width: 50%;

      @media screen and (max-width: $screen-md) {
        width: 100%;

        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }

    span {
      color: $gray-dark;
      font-size: 14px;
      margin-bottom: 5px;
    }

    label {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      color: var(--primary-darker-color);
      font-family: $font-family;
      font-size: $font-size-xxs;



      input {
        display: block;
        height: 24px;
        padding: .65rem;
        margin-right: 1rem;
        font-size: $font-size-xxs;
        background: $white;
        border: 1px solid $gray;
        border-radius: 8px;
        margin-top: .25rem;

        @media screen and (max-width: $screen-md) {
          margin-right: 0;
        }
      }

      >div {
        &:hover:not(.Mui-disabled) {

          &:after,
          &:before {
            border: none !important;
          }
        }

        &:after,
        &:before {
          border: none !important;
        }
      }
    }
  }

  .submitButtonWrapper {
    @media screen and (max-width: $screen-md) {
      width: 100%;
    }

    button {
      min-width: 250px;
      padding: .65rem;
      height: 47px;
      margin-top: 0.25rem;
      font-size: $font-size-xs;

      @media screen and (max-width: $screen-md) {
        min-width: 100%;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

}

.disclaimer {
  margin-top: 2rem !important;
  color: $black-40;
  text-align: center;

  .helpLink {
    font-size: $font-size-xs;
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $white;
    }
  }
}

.submitError {
  font-size: $font-size-xxxs;
  color: $error;
  height: 13px;
  margin-left: .25rem;
  margin-top: .25rem;
}