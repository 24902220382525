@import "src/styles/fonts";
@import "src/styles/colors";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 18px;
  flex: none;
  order: 0;
  font-family: $font-family;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.primary {
  background: var(--primary-darker-color);
  color: $white;
  border: 1px solid $gray-lines;
  box-sizing: border-box;
  flex-grow: 1;

  &:hover {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    filter: brightness(95%);
    cursor: pointer;
  }

  &:active {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    filter: brightness(85%);
    cursor: pointer;
  }
}

.secondary {
  background: $white;
  color: var(--primary-darker-color);
  border: 1px solid $gray-lines;
  flex-grow: 0;

  &:hover {
    background: $white;
    filter: brightness(95%);
    cursor: pointer;
  }

  &:active {
    background: $white;
    filter: brightness(95%);
    cursor: pointer;
  }
}

.tertiary {
  background: var(--primary-color);
  color: $white;
  border: 1px solid var(--primary-color);
  flex-grow: 0;
  filter: brightness(90%);

  &:hover {
    background: var(--primary-color);
    filter: brightness(85%);
    cursor: pointer;
  }

  &:active {
    background: var(--primary-color);
    filter: brightness(75%);
    cursor: pointer;
  }
}

.isFullWidth {
  width: 100%;
}

.disabled {
  cursor: not-allowed !important;
}