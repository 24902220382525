@import "src/styles/colors";

.container {
  border: 1px solid $gray-lines;
  border-radius: 8px;
}

.arrowColor {
  fill: var(--primary-darker-color);
  transition: transform .3s ease;
}