@import "/src/styles/colors";
@import "/src/styles/fonts";

.title {
  color: var(--headings-color);
  font-weight: $font-weight-semibold;
}

.xl {
  font-size: $font-size-xl;
}

.l {
  font-size: $font-size-l;
}

.m {
  font-size: $font-size-m;
}

.s {
  font-size: $font-size-s;
}

.xs {
  font-size: $font-size-xs;
}

.xxs {
  font-size: $font-size-xxs;
}